import React from "react";

import {DetailedHTMLProps, HTMLAttributes} from "react";
import clsx from "clsx";
import Heading from "@js/component/Heading";
import FeaturedImage from "@js/component/FeaturedImage";
import PropositionBanner from "@js/component/icon/PropositionBanner";
import AdvisorSearch from "@js/component/AdvisorSearch";

import * as classNames from "@css/component/block/BlockSearchAdvisors.module.scss";

/**
 * @type BlockHowDoesItWorkProps
 */
export type BlockSearchAdvisorsProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>, "children">;

/**
 * @const BlockHowDoesItWork
 */
const BlockSearchAdvisors = (props: BlockSearchAdvisorsProps) => {
    const {
        title,
        content,
        image,
        advertorial,
        className,
        ...restProps
    } = props;

    const siteTitle = title ? title.split('|') : '';

    return (
        <section { ...restProps } className={ clsx(classNames.blockSearchAdvisors, className) }>
            <div className={ classNames.container }>
                <div className={ classNames.headerContent }>
                    <Heading className={ classNames.heading } variant="extraLarge">
                        <strong>{ siteTitle[0] }</strong>
                        { siteTitle[1] }
                    </Heading>
                    { content &&
                        <p className={ classNames.usps } dangerouslySetInnerHTML={{ __html: content }}/>
                    }
                </div>
                <a href={ advertorial?.advertorialUrl } className={ classNames.advertorial }>
                    <FeaturedImage
                        className={ classNames.featuredImage }
                        gatsbyImageData={ image }
                        variant="home"
                    />
                </a>
                { advertorial &&
                    <a href={ advertorial.advertorialUrl } target="_blank" rel="noreferrer" className={ classNames.advertorial }>
                        <PropositionBanner banner={ advertorial.advertorialImage.sourceUrl } className={ classNames.image }/>
                    </a>
                }
                <AdvisorSearch />
            </div>
        </section>
    );
};

export default BlockSearchAdvisors;