import React from "react";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";

import clsx from "clsx";

import Heading from "@js/component/Heading";
import LinkButton from "@js/component/LinkButton";
import LongArrowRightIcon from "@js/component/icon/LongArrowRightIcon";

import * as classNames from "@css/component/block/BlockCTA.module.scss";

/**
 * @type BlockCTAProps
 */
export type BlockCTAProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>, "children">;

/**
 * @const BlockWhoWeAre
 */
const BlockCTA = (props: BlockCTAProps) => {
    const {
        className,
        ...restProps
    } = props;

    return (
        <section { ...restProps } className={ clsx(classNames.blockCTA, className) }>
            <div className={ classNames.container }>
                <div className={ classNames.block }>
                    <Heading className={ classNames.heading } element="h2" variant="large">
                        <strong>Energielabel</strong> of <strong>maatwerkadvies</strong> aanvragen?
                    </Heading>
                    <p className={ classNames.paragraph }>
                        Woninglabel.nl is een initiatief van adviseurs met ruim 20 jaar ervaring in de energieprestatiemarkt.
                        Het Woninglabel.nl Team staat voor je klaar om jou gemakkelijk en snel aan een energielabel en/of maatwerkadvies te helpen.
                        Dit doen we door alle adviseurs bij jou in de buurt overzichtelijk weer te geven en alle informatie over energielabels en maatwerkadviezen te verzamelen op één overzichtelijke plek.
                        We staan voor het makkelijk maken van moeilijke dingen. Daarom beoordelen onze klanten ons gemiddeld met 4,9 uit 5 sterren.
                        Hier zijn we trots op en dit geeft ons de energie om jou de beste service te verlenen!
                    </p>
                    <LinkButton className={ classNames.linkButton } to="/vergelijk" variant="white">
                        <span className={ classNames.text }>Nu aanvragen</span>
                        <LongArrowRightIcon className={ classNames.icon } />
                    </LinkButton>
                </div>
            </div>
        </section>
    );
};

export default BlockCTA;
