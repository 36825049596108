import React, {useRef} from "react";

import { GatsbyImage } from "gatsby-plugin-image";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";
import { IGatsbyImageData } from "gatsby-plugin-image";

import clsx from "clsx";

import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import { shuffle } from "lodash";

import Heading from "@js/component/Heading";
import LinkButton from "@js/component/LinkButton";
import LongArrowRightIcon from "@js/component/icon/LongArrowRightIcon";

import * as classNames from "@css/component/block/BlockAdvisors.module.scss";

/**
 * @type LogoPlaceholderData
 */
type LogoPlaceholderData = {
    logoPlaceholder: {
        childImageSharp: {
            gatsbyImageData: IGatsbyImageData;
        };
    };
};

/**
 * @type AllAdvisorData
 */
type AllAdvisorData = {
    allAdvisor: {
        edges: {
            node: {
                companyImageFile: {
                    childImageSharp: {
                        gatsbyImageData: IGatsbyImageData;
                    }
                }
                companyName: string;
                completedRequests: number;
                url: string;
            };
        }[];
    }
};

/**
 * @type Data
 */
type Data = AllAdvisorData & LogoPlaceholderData;

/**
 * @type BlockAdvisorsProps
 */
export type BlockAdvisorsProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>, "children">;

/**
 * @const BlockAdvisors
 */
const BlockAdvisors = (props: BlockAdvisorsProps) => {
    const {
        className,
        ...restProps
    } = props;

    const data: Data = useStaticQuery(graphql`{
          allAdvisor {
            edges {
              node {
                companyImageFile {
                  childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 200, quality: 50)
                  }
                }
                companyName
                completedRequests
                url
              }
            }
          }
          logoPlaceholder: file(relativePath: {eq: "logo-placeholder.png"}) {
            childImageSharp {
              gatsbyImageData(width: 200, quality: 50, layout: FIXED)
            }
          }
        }
    `);

    const sortedAdvisors = useRef(data.allAdvisor.edges.sort((a, b) => a.node.completedRequests < b.node.completedRequests ? 1 : -1));

    return (
        <section { ...restProps } className={ clsx(classNames.blockAdvisors, className) }>
            <div className={ classNames.container }>
                <Heading className={ classNames.heading } element="h2" variant="large">
                    Adviseurs door<br /><strong>heel Nederland</strong> om uit te kiezen.
                </Heading>
                <div className={ classNames.advisorBlock }>
                    { sortedAdvisors.current.slice(0, 24).map(({ node }, index) => (
                        <div key={ index } className={ classNames.advisor }>
                            { node.companyImageFile?.childImageSharp?.gatsbyImageData &&
                                <GatsbyImage
                                    alt={ node.companyName ? node.companyName : "company" }
                                    className={ classNames.image }
                                    image={ node.companyImageFile.childImageSharp.gatsbyImageData }
                                />
                            }
                            { !(node.companyImageFile?.childImageSharp?.gatsbyImageData) &&
                                <GatsbyImage
                                    alt={ node.companyName ? node.companyName : "company" }
                                    className={ classNames.image }
                                    image={ data.logoPlaceholder.childImageSharp.gatsbyImageData }
                                />
                            }
                        </div>
                    )) }
                </div>
                <LinkButton className={ classNames.linkButton } to="/over-ons/energielabel-adviseurs">
                    <span className={ classNames.text }>Alle adviseurs</span>
                    <LongArrowRightIcon className={ classNames.icon } />
                </LinkButton>
            </div>
        </section>
    );
};

export default BlockAdvisors;
