import React from "react";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";

import clsx from "clsx";

import BoxLabelIcon from "@js/component/icon/label/BoxLabelIcon";
import CompareLabelIcon from "@js/component/icon/label/CompareLabelIcon";
import CouchLabelIcon from "@js/component/icon/label/CouchLabelIcon";
import GroupLabelIcon from "@js/component/icon/label/GroupLabelIcon";
import Heading from "@js/component/Heading";

import * as classNames from "@css/component/block/BlockHowDoesItWork.module.scss";

/**
 * @type BlockHowDoesItWorkProps
 */
export type BlockHowDoesItWorkProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>, "children">;

/**
 * @const BlockHowDoesItWork
 */
const BlockHowDoesItWork = (props: BlockHowDoesItWorkProps) => {
    const {
        className,
        ...restProps
    } = props;

    return (
        <section { ...restProps } className={ clsx(classNames.blockHowDoesItWork, className) }>
            <div className={ classNames.container }>
                <Heading className={ classNames.heading } element="h2" variant="large">Hoe werkt <strong>woninglabel.nl?</strong></Heading>
                <div className={ classNames.blocks }>
                    <div className={ classNames.block }>
                        <CompareLabelIcon className={ classNames.icon } />
                        <Heading className={ classNames.heading } element="h2" variant="small">Vergelijk adviseurs</Heading>
                        <p className={ classNames.paragraph }>
                            Vergelijk onafhankelijk alle gecertificeerde adviseurs op prijs, levertijd en beoordeling.
                        </p>
                    </div>
                    <div className={ classNames.block }>
                        <GroupLabelIcon className={ classNames.icon } />
                        <Heading className={ classNames.heading } element="h2" variant="small">Selecteer jouw adviseur</Heading>
                        <p className={ classNames.paragraph }>
                            Je betaalt pas bij ontvangst en na registratie van het energielabel en/of maatwerkadvies.
                        </p>
                    </div>
                    <div className={ classNames.block }>
                        <CouchLabelIcon className={ classNames.icon } />
                        <Heading className={ classNames.heading } element="h2" variant="small">Leun achterover</Heading>
                        <p className={ classNames.paragraph }>
                            De adviseur maakt een afspraak, komt langs en stelt het energielabel en/of maatwerkadvies op.
                        </p>
                    </div>
                    <div className={ classNames.block }>
                        <BoxLabelIcon className={ classNames.icon } />
                        <Heading className={ classNames.heading } element="h2" variant="small">Ontvang</Heading>
                        <p className={ classNames.paragraph }>
                            Je ontvangt je energielabel en/of maatwerkadvies per e-mail en deze wordt voor je geregistreerd.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BlockHowDoesItWork;
