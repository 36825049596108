import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

import { DetailedHTMLProps } from "react";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { HTMLAttributes } from "react";

import clsx from "clsx";

import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import Heading from "@js/component/Heading";
import LinkButton from "@js/component/LinkButton";
import LongArrowRightIcon from "@js/component/icon/LongArrowRightIcon";

import * as classNames from "@css/component/block/BlockAboutCustomAdvise.module.scss";

/**
 * @type FileData
 */
type FileData = {
    file: {
        childImageSharp: {
            gatsbyImageData: IGatsbyImageData;
        };
    };
};

/**
 * @type BlockAboutEnergylabelsProps
 */
export type BlockAboutCustomAdviseProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>, "children">;

/**
 * @const BlockWhoWeAre
 */
const BlockAboutCustomAdvise = (props: BlockAboutCustomAdviseProps) => {
    const {
        className,
        ...restProps
    } = props;

    const data: FileData = useStaticQuery(graphql`
        query {
            file(relativePath: { eq: "about-custom-advise.png" }) {
                childImageSharp {
                    gatsbyImageData(width: 520, quality: 75)
                }
            }
        }
    `);

    return (
        <section { ...restProps } className={ clsx(classNames.blockAboutCustomAdvise, className) }>
            <div className={ classNames.container }>
                <div className={ classNames.blocks }>
                    <div className={ classNames.block }>
                        <Heading className={ classNames.heading } element="h2" variant="large">Over <strong>maatwerkadviezen</strong></Heading>
                        <p className={ classNames.paragraph }>
                            Het maatwerkadvies is precies zoals je waarschijnlijk al verwacht, een advies op maat voor verduurzaming.
                            Een gecertificeerd maatwerkadvies geeft aan welke specifieke maatregelen de energieprestatie van jouw woning of bedrijfspand kunnen verbeteren.
                            Gecertificeerde maatwerkadviezen gaan aanzienlijk verder dan de gebruikelijke ‘online woningscan’.
                            Een EP Adviseur komt persoonlijk langs en neemt alle relevante variabelen van jouw woning op.
                            Het maatwerkadvies kan alleen worden opgesteld door een erkende EP Adviseur binnen een gecertificeerd werkproces.
                        </p>
                        <LinkButton className={ classNames.linkButton } to="/over-maatwerkadvies" variant="green">
                            <span className={ classNames.text }>Over maatwerkadviezen</span>
                            <LongArrowRightIcon className={ classNames.icon } />
                        </LinkButton>
                    </div>
                    <div className={ classNames.block }>
                        <GatsbyImage
                            alt="over ons"
                            className={ classNames.image }
                            image={ data.file.childImageSharp.gatsbyImageData }
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BlockAboutCustomAdvise;
