import React from "react";

import { SVGProps } from "react";

/**
 * @type CouchLabelIconProps
 */
export type CouchLabelIconProps = Omit<SVGProps<SVGSVGElement>, "children">;

/**
 * @const CouchLabelIcon
 */
const CouchLabelIcon = (props: CouchLabelIconProps) => {
    return (
        <svg {...props} xmlns="http://www.w3.org/2000/svg" width="48.514" height="47.34" viewBox="0 0 48.514 47.34">
            <path d="M42.491,42.153a5.077,5.077,0,1,0-5.077-5.077A5.078,5.078,0,0,0,42.491,42.153Zm0,3.384H38.154L39.2,49.747l2.739,10.978a6.79,6.79,0,0,0,6.578,5.119H58.768l3.966,10.5,1.132,2.993,3.046-.952,5.077-1.6,3.226-1.015-2.02-6.462-3.226,1.015-2.031.635-3.67-9.7-.825-2.189H51.9l-.846-3.384H61.1V48.922H49.365l-.2-.825-.645-2.559H42.491Zm-8.514-.624-.624-3.332L26.7,42.83l.624,3.332L32.4,73.237,32.908,76h23.12V69.229H38.535Z" transform="translate(-26.7 -32)" fill="currentColor"/>
        </svg>

    );
};

export default CouchLabelIcon;
