import React from "react";
import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";
import { createRef } from "react";
import Moment from "react-moment";
import 'moment/locale/nl';
import Slider from "react-slick";

import {graphql, Link, useStaticQuery} from "gatsby";
import {GatsbyImage, IGatsbyImageData} from "gatsby-plugin-image";

import clsx from "clsx";

import ChevronLeftIcon from "@js/component/icon/ChevronLeftIcon";
import ChevronRightIcon from "@js/component/icon/ChevronRightIcon";
import Heading from "@js/component/Heading";
import Stars from "@js/component/Stars";

import * as classNames from "@css/component/block/BlockAdvisorReviews.module.scss";
import CalendarIcon from "@js/component/icon/CalendarIcon";
import SiteReviews from "@js/component/SiteReviews";

/**
 * @type BlockAdvisorReviewsProps
 */
type BlockAdvisorReviewsProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>, "children"> & {
    reviews: {
        node: {
            buildingOwnerInitials: string;
            buildingOwnerName: string;
            companyName: string;
            createdAt: string;
            review: number;
            reviewMessage: string;
            url: string;
            advisorImageFile: {
                childImageSharp: {
                    gatsbyImageData: IGatsbyImageData;
                }
            },
        };
    }[];
};

/**
 * @type LogoPlaceholderData
 */
type LogoPlaceholderData = {
    logoPlaceholder: {
        childImageSharp: {
            gatsbyImageData: IGatsbyImageData;
        };
    };
};

/**
 * @const BlockAdvisorReviews
 */
const BlockAdvisorReviews = (props: BlockAdvisorReviewsProps) => {
    const {
        className,
        reviews,
        ...restProps
    } = props;

    const sliderRef = createRef<Slider>();

    const onNextButtonClick = () => {
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };

    const onPreviousButtonClick = () => {
        if (sliderRef.current) {
            sliderRef.current.slickPrev();
        }
    };

    const data: LogoPlaceholderData = useStaticQuery(graphql`
        query {
            logoPlaceholder: file(relativePath: { eq: "logo-placeholder.png" }) {
                childImageSharp {
                    gatsbyImageData(layout: FIXED, width: 200, quality: 50)
                }
            }
        }
    `);

    return (
        <section { ...restProps } className={ clsx(classNames.blockAdvisorReviews, className) }>
            { reviews.length > 0 &&
                <>
                    <div className={ classNames.container }>
                        <Heading className={ classNames.heading } element="h2" variant="large">
                            <strong>Klantervaringen</strong> bij aangesloten adviseurs
                        </Heading>
                        <div className={ classNames.buttons }>
                            <button aria-label="Vorige" className={ classNames.button } onClick={ onPreviousButtonClick } type="button">
                                <ChevronLeftIcon className={ classNames.icon } />
                            </button>
                            <button aria-label="Volgende" className={ classNames.button } onClick={ onNextButtonClick } type="button">
                                <ChevronRightIcon className={ classNames.icon } />
                            </button>
                        </div>
                    </div>
                    <Slider
                        arrows={ false }
                        centerMode
                        draggable={ false }
                        ref={ sliderRef }
                        swipeToSlide={ false }
                        variableWidth
                    >
                        { reviews.map((review, index) => (
                            <div className={ classNames.slide } key={ index }>
                                <Link to={`/over-ons/energielabel-adviseurs/${ review.node.url }`}>
                                    <article className={ classNames.review }>
                                        <div className={ classNames.starsAndLogo }>
                                            <Stars
                                                backgroundColor="#E5E5E5"
                                                className={ classNames.stars }
                                                foregroundColor="#00C45E"
                                                foregroundPercentage={ (review.node.review / 5) * 100 }
                                            />
                                            <div className={ classNames.logoWrapper }>
                                                { review.node.advisorImageFile?.childImageSharp?.gatsbyImageData &&
                                                    <GatsbyImage
                                                        alt={ review.node.companyName }
                                                        className={ classNames.logo }
                                                        image={ review.node.advisorImageFile.childImageSharp.gatsbyImageData }
                                                        width={ 100 }
                                                        height={ 100 }
                                                    />
                                                }
                                                { !(review.node.advisorImageFile?.childImageSharp?.gatsbyImageData) &&
                                                    <GatsbyImage
                                                        alt={ review.node.companyName }
                                                        className={ classNames.logo }
                                                        image={ data.logoPlaceholder.childImageSharp.gatsbyImageData }
                                                    />
                                                }
                                            </div>
                                        </div>
                                        <p className={ clsx(classNames.paragraph, { [classNames.truncated]: review.node.reviewMessage.length > 300 }) }>
                                            { review.node.reviewMessage }
                                        </p>
                                        <div className={ classNames.dateWrapper }>
                                            <label className={ classNames.heading }>
                                                { review.node.buildingOwnerInitials &&
                                                    <span title={ review.node.buildingOwnerInitials.length > 25 ? review.node.buildingOwnerInitials : '' }>
                                                        { review.node.buildingOwnerInitials }
                                                    </span>
                                                }
                                                { review.node.buildingOwnerName &&
                                                    <span
                                                        title={review.node.buildingOwnerName.length > 25 ? review.node.buildingOwnerName : ''}>
                                                    { review.node.buildingOwnerName }
                                                </span>
                                                }
                                            </label>
                                            <Moment
                                                className={ classNames.date }
                                                date={ review.node.createdAt }
                                                format="DD MMMM YYYY"
                                                locale="nl"
                                            />
                                        </div>
                                    </article>
                                </Link>
                            </div>
                        )) }
                    </Slider>
                </>
            }
            { reviews.length == 0 &&
                <div className={ classNames.fill }/>
            }
        </section>
    );
};

export default BlockAdvisorReviews;
